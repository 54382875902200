<script>

import AdvancedTable from "../../../components/elements/advanced-table.vue";
import StatusBadge from "../../../components/elements/status-badge.vue";

export default {
    components: {
        StatusBadge,
        AdvancedTable,
    },

    computed: {
        fields: function () {
            return [
                {
                    key: "name",
                    label: this.$t('projects::projects.columns.name').ucFirst(),
                    formatter: (value, index, item) => item.translation_key ? this.$t(item.translation_key).ucFirst() : value,
                    thClass: '',
                    sortable: true
                },
                {
                    key: "target_type",
                    label: this.$t('projects::projects.relationships.target').ucFirst(),
                    formatter: (value, index, item) => item.target ? (item.target.translation_key ? this.$t(item.target.translation_key).ucFirst() : item.target.name) : value,
                    thClass: '',
                    sortable: true
                },
                {
                    key: "project_type_id",
                    label: this.$t('projects::projects.relationships.project_type').ucFirst(),
                    formatter: (value, index, item) => item.project_type ? (item.project_type.translation_key ? this.$t(item.project_type.translation_key).ucFirst() : item.project_type.name) : value,
                    thClass: '',
                    sortable: true
                },
                {
                    key: "process_id",
                    label: this.$t('projects::projects.relationships.process').ucFirst(),
                    formatter: (value, index, item) => item.process ? (item.process.translation_key ? this.$t(item.process.translation_key).ucFirst() : item.process.name) : value,
                    thClass: '',
                    sortable: true
                },
                {
                    key: "step_id",
                    label: this.$t('projects::projects.relationships.step').ucFirst(),
                    formatter: (value, index, item) => item.step ? (item.step.translation_key ? this.$t(item.step.translation_key).ucFirst() : item.step.name) : value,
                    thClass: '',
                    sortable: true
                },
                /*{
                    key: "user",
                    label: this.$t('projects::projects.relationships.user').ucFirst(),
                    formatter: (value) => value.translation_key ? this.$t(value.translation_key).ucFirst() : value.name,
                    thClass: '',
                    sortable: true
                },*/
                {
                    key: "status_id",
                    label: this.$t('projects::projects.relationships.status').ucFirst(),
                    formatter: (value, index, item) => item.status ? (item.status.translation_key ? this.$t(item.status.translation_key).ucFirst() : item.status.name) : value,
                    thClass: '',
                    sortable: true
                },
                {
                    key: "users",
                    label: this.$t('projects::projects.relationships.users').ucFirst(),
                    thClass: '',
                    sortable: true
                },
                {
                    key: "followers",
                    label: this.$t('projects::projects.relationships.followers').ucFirst(),
                    thClass: '',
                    sortable: true
                },
                {
                    key: "contributors",
                    label: this.$t('projects::projects.relationships.contributors').ucFirst(),
                    thClass: '',
                    sortable: true
                },
                {
                    key: "actions",
                    label: this.$t('base.actions').ucFirst(),
                    thClass: '',
                    tdClass: '',
                    sortable: false
                },
            ];
        },
    },

    data() {
        return {};
    },

    mounted() {
        this.$store.dispatch('app/title', {key: 'projects::projects.project', count: 2})
    },

    unmounted() {
        this.$store.dispatch('app/title', '')
    }
};
</script>

<template>
    <advanced-table
        :caption="$tc('projects::projects.project', 2).ucFirst()"
        :current-page="1"
        :edit-link="{ name: 'accessible-project-edit' }"
        :empty-filtered-text="$t('base.empty_filtered').ucFirst()"
        :empty-text="$t('base.empty_table').ucFirst()"
        :fields="fields"
        :queries="{ with: ['target', 'projectType', 'process', 'step', 'user', 'status', 'followers', 'contributors', 'users'] }"
        :per-page="10"
        borderless
        deletable
        editable
        hover
        action="accessible"
        moduleNamespace="project"
        module-pager="accessiblePager"
        outlined
        permission="projects"
        primary-key="id"
        responsive="sm"
        searchable
        show-empty
        sort-by="id"
        sort-direction="asc"
        sort-icon-left
        archive
    >
        <template #users="{value}">
            <div class="avatar-group">
                <div v-for="(user, index) in value" :key="index" class="avatar-group-item">
                    <a v-if="user.image && user.image" href="javascript: void(0);" class="d-inline-block">
                        <img :src="user.image"
                             :alt="user.name"
                             :title="user.name"
                             class="rounded-circle avatar-sm">
                    </a>
                    <div v-else class="avatar-sm cursor-pointer">
                    <span :title="user.name" class="avatar-title rounded-circle bg-primary text-white font-size-16 user-select-none"> {{ user.name.firstLetters() }} </span>
                </div>
                </div>
            </div>
        </template>
        <template #contributors="{value}">
            <div class="avatar-group">
                <div v-for="(user, index) in value" :key="index" class="avatar-group-item">
                    <a v-if="user.image && user.image" href="javascript: void(0);" class="d-inline-block">
                        <img :src="user.image"
                             :alt="user.name"
                             :title="user.name"
                             class="rounded-circle avatar-sm">
                    </a>
                    <div v-else class="avatar-sm cursor-pointer">
                        <span :title="user.name" class="avatar-title rounded-circle bg-primary text-white font-size-16 user-select-none"> {{ user.name.firstLetters() }} </span>
                    </div>
                </div>
            </div>
        </template>
        <template #followers="{value}">
            <div class="avatar-group">
                <div v-for="(user, index) in value" :key="index" class="avatar-group-item">
                    <a v-if="user.image && user.image" href="javascript: void(0);" class="d-inline-block">
                        <img :src="user.image"
                             :alt="user.name"
                             :title="user.name"
                             class="rounded-circle avatar-sm">
                    </a>
                    <div v-else class="avatar-sm cursor-pointer">
                        <span :title="user.name" class="avatar-title rounded-circle bg-primary text-white font-size-16 user-select-none"> {{ user.name.firstLetters() }} </span>
                    </div>
                </div>
            </div>
        </template>
        <template #status_id="{item, formatted}">
            <status-badge :bg-color="item?.status?.color">{{ formatted }}</status-badge>
        </template>
    </advanced-table>
</template>
